import { Routes, Route } from 'react-router-dom';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PhoneVerification from 'pages/auth/PhoneVerification';
import ResetPassword from 'pages/auth/ResetPassword';
import CreateNewPassword from 'pages/auth/CreateNewPassword';
import SignIn from 'pages/auth/SignIn';
import EmailVerification from 'pages/auth/EmailVerification';
import CreateAccount from 'pages/auth/CreateAccount';
import FilePreviewPage from 'pages/applications/FilePreviewPage';
import { AppRouteWithParams } from 'enums/AppRouteWithParams';
import NotFoundPage from 'pages/notFound';
import ForbiddenPage from 'pages/forbidden';
import AdminPasswordRequiredPage from 'pages/AdminPasswordRequiredPage';
import AuthRouterLayout from 'route-layout/AuthRouterLayout';
import CustomHtmlHead from 'components/PageLayout/CustomHtmlHead';
import ApplicationPage from 'pages/applications/ApplicationPage';
import HomePage from 'pages/HomePageWrapper';
import CreateApplicationPage from 'pages/applications/CreateApplicationPage';
import OnboardingPage from 'pages/auth/OnboardingPage';
import UnAuthRouterLayout from 'route-layout/UnAuthRouterLayout';

export const history = createBrowserHistory({ window });

const Router = () => (
  <HistoryRouter history={history}>
    <CustomHtmlHead />
    <Routes>
      <Route element={<AuthRouterLayout />}>
        <Route path={AppRouteWithParams.Home} element={<HomePage />} />
        <Route path={AppRouteWithParams.Start} element={<OnboardingPage />} />
        <Route path={AppRouteWithParams.CreateAccount} element={<CreateAccount />} />
        <Route path={AppRouteWithParams.AcceptInvite} element={<CreateAccount />} />
        <Route path={AppRouteWithParams.SignIn} element={<SignIn />} />
        <Route path={AppRouteWithParams.ConfirmEmail} element={<EmailVerification />} />
        <Route path={AppRouteWithParams.PhoneVerification} element={<PhoneVerification />} />
        <Route path={AppRouteWithParams.ResetPassword} element={<ResetPassword />} />
        <Route path={AppRouteWithParams.CreateNewPassword} element={<CreateNewPassword />} />
        <Route path={AppRouteWithParams.DocumentPreview} element={<FilePreviewPage />} />
        <Route path={AppRouteWithParams.Application} element={<ApplicationPage />} />
        <Route path={AppRouteWithParams.Apply} element={<CreateApplicationPage />} />
        <Route path={AppRouteWithParams.Forbidden} element={<ForbiddenPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route element={<UnAuthRouterLayout />}>
        <Route path={AppRouteWithParams.AdminPasswordRequired} element={<AdminPasswordRequiredPage />} />
      </Route>
    </Routes>
  </HistoryRouter>
);

export default Router;
