import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import { Variable } from 'product_modules/api/Core/VariablesApi';
import { variablesApi } from 'store/api';
import rejectWithValue from 'utils/rejectWithValue';

export enum VariablesActionType {
  LoadVariablesBySystemNames = 'variables/loadVariablesBySystemName',
}

const variablesAdapter = createEntityAdapter<Variable>({});

export interface IVariablesState extends EntityState<Variable> {
  variablesBySystemName: Record<string, Variable>,
  loadingState: Record<string, string>;
}

const initialState: IVariablesState = variablesAdapter.getInitialState({
  variablesBySystemName: {},
  loadingState: {},
});

export const loadVariablesBySystemNames = createAsyncThunk<Variable[], string[]>(
  VariablesActionType.LoadVariablesBySystemNames,
  async (systemNames: string[], thunkApi) => {
    try {
      const { items } = await variablesApi.search({ systemNames });

      return items;
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

const variablesSlice = createSlice({
  name: 'variablesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadVariablesBySystemNames.pending, (state, action) => {
      action.meta.arg.forEach((systemName) => {
        if (!state.loadingState[systemName]) {
          state.loadingState[systemName] = action.meta.requestId;
        }
      });
    });

    builder.addCase(loadVariablesBySystemNames.fulfilled, (state, action) => {
      action.meta.arg.forEach((systemName) => {
        if (state.loadingState[systemName] === action.meta.requestId) {
          delete state.loadingState[systemName];
        }
      });

      variablesAdapter.addMany(state, action.payload);

      action.payload.forEach((variable) => {
        state.variablesBySystemName[variable.systemName] = variable;
      });
    });

    builder.addCase(loadVariablesBySystemNames.rejected, (state, action) => {
      action.meta.arg.forEach((systemName) => {
        if (state.loadingState[systemName] === action.meta.requestId) {
          delete state.loadingState[systemName];
        }
      });
    });
  },
});

export default variablesSlice.reducer;
