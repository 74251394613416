import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BrandingLanguage, ILegalDocument } from 'api/digifi/SettingsApi';
import { BorrowerType } from 'product_modules/enums/BorrowerType';
import DigitalLendingPortalEnvironment from 'enums/DigitalLendingPortalEnvironment';
import { borrowerTypesApi, environmentApi, settingsApi } from 'store/api';
import rejectWithValue from 'utils/rejectWithValue';

enum SettingsActionType {
  GetSettings = 'settings/getSettings',
  GetEnvironment = 'settings/getEnvironment',
}

export const DEFAULT_PHONE_NUMBER_FORMAT = 'US';
const DEFAULT_CURRENCY = '$';

interface IBrandingParams {
  accentColor: string | null;
  brandColor: string | null;
  faviconId: string | null;
  logoId: string | null;
  companyName: string | null;
  country: string | null;
  language: BrandingLanguage | null;
}

interface IPortalConfiguration {
  borrowerLockPeriodDays: number;
}

interface IVariablesSettings {
  phoneNumberFormat: string;
  currency: string;
}

export interface ISettingsState {
  branding: IBrandingParams;
  portalConfiguration: IPortalConfiguration;
  variablesSettings: IVariablesSettings;
  legalDocuments: ILegalDocument[];
  selectedDocument: ILegalDocument | null;
  environment: DigitalLendingPortalEnvironment | null;
  borrowerTypes: BorrowerType[];
}

const initialState: ISettingsState = {
  branding: {
    accentColor: null,
    brandColor: null,
    faviconId: null,
    logoId: null,
    companyName: null,
    country: null,
    language: null,
  },
  portalConfiguration: {
    borrowerLockPeriodDays: 0,
  },
  variablesSettings: {
    phoneNumberFormat: DEFAULT_PHONE_NUMBER_FORMAT,
    currency: DEFAULT_CURRENCY,
  },
  legalDocuments: [],
  selectedDocument: null,
  environment: null,
  borrowerTypes: [BorrowerType.Person, BorrowerType.Company],
};

export const getEnvironment = createAsyncThunk(
  SettingsActionType.GetEnvironment,
  async (_, thunkApi) => {
    try {
      return await environmentApi.getEnvironment();
    } catch (error) {
      return rejectWithValue(error, thunkApi);
    }
  },
);

export const getSettings = createAsyncThunk(SettingsActionType.GetSettings, async (_, thunkApi) => {
  try {
    const [branding, legalDocuments, portalConfiguration, variablesSettings, borrowerTypes, environment] =
      await Promise.all([
        settingsApi.getBranding(),
        settingsApi.getLegalDocuments(),
        settingsApi.getPortalConfiguration(),
        settingsApi.getVariablesSettings(),
        borrowerTypesApi.getBorrowerTypes(),
        environmentApi.getEnvironment(),
      ]);

    return {
      branding,
      legalDocuments,
      portalConfiguration,
      variablesSettings,
      borrowerTypes,
      environment,
    };
  } catch (error) {
    return rejectWithValue(error, thunkApi);
  }
});

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    setSelectedDocument(state, { payload }: PayloadAction<ILegalDocument | null>) {
      state.selectedDocument = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.fulfilled, (state, { payload }) => {
        state.branding = payload.branding || initialState.branding;
        state.portalConfiguration = payload.portalConfiguration || initialState.portalConfiguration;
        state.variablesSettings = payload.variablesSettings || initialState.variablesSettings;
        state.legalDocuments = payload.legalDocuments || initialState.legalDocuments;
        state.borrowerTypes = payload.borrowerTypes || initialState.borrowerTypes;
        state.environment = payload.environment || initialState.environment;
      })
      .addCase(getEnvironment.fulfilled, (state, { payload }) => {
        state.environment = payload;
      });
  },
});

export const { setSelectedDocument } = settingsSlice.actions;

export default settingsSlice.reducer;
