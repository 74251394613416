import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import RouterLayout from 'components/PageLayout/WorkflowWrapper';

const UnAuthRouterLayout: FC = () => {
  return (
    <>
      <RouterLayout>
        <Outlet />
      </RouterLayout>
    </>
  );
};

export default UnAuthRouterLayout;
